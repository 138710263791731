import React,  { useEffect, useState } from 'react';
import {Row, Col, Modal, Form, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RendConf from '../../RendConf.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)


export default function ModalVendeg({rendezveny, onSave, onHide, jegyTipus, ...otherProps}) {
    let me = this;
    const [nev, setNev] = useState('');
    const [kivalasztottHangszer1, setKivalasztottHangszer1] = useState(undefined);
    const [kivalasztottHangszer2, setKivalasztottHangszer2] = useState(undefined);
    const [kivalasztottHangszer3, setKivalasztottHangszer3] = useState(undefined);
    const [kivalasztottRendezvenyresz1, setKivalasztottRendezvenyresz1] = useState(undefined);
    const [kivalasztottRendezvenyresz2, setKivalasztottRendezvenyresz2] = useState(undefined);
    const [kivalasztottRendezvenyresz3, setKivalasztottRendezvenyresz3] = useState(undefined);
    const [hangszerek, setHangszerek] = useState([]);
    
    useEffect(() => {
        var ujHangszerek = [];
        var ujHangszerNevek = [];
        for (var resz of rendezveny.rendezvenyReszek){
            if (!RendConf.inArray(resz.Nev, ujHangszerNevek)){
                var ujHangszer = {
                    Nev: resz.Nev,
                    Masodikkentisvalaszthato: resz.Masodikkentisvalaszthato
                }
                ujHangszerek.push(ujHangszer);
                ujHangszerNevek.push(resz.Nev);
            }
        }
        setHangszerek(ujHangszerek);
    }, [rendezveny])

    function onSave_inside(){
        let rendezvenyReszek_out = [];
        rendezvenyReszek_out.push(kivalasztottRendezvenyresz1);
        rendezvenyReszek_out.push(kivalasztottRendezvenyresz2);
        rendezvenyReszek_out.push(kivalasztottRendezvenyresz3);
        if (nev == ''){
            // do nothing
        }else{
            let data = {
                Vendegneve: nev,
                rendezvenyReszek: rendezvenyReszek_out,
                jegyTipus: jegyTipus
            };
            onSave(data)
            setNev('')
            setKivalasztottRendezvenyresz1(undefined);
            setKivalasztottRendezvenyresz2(undefined);
            setKivalasztottRendezvenyresz3(undefined);
            setKivalasztottHangszer1(undefined)
            setKivalasztottHangszer2(undefined)                
            setKivalasztottHangszer3(undefined)                
        }
    }

    function onSelect_hangszer(rendezvenyresz_sorszam, e){
        if (e.target.value == -1){
            if (rendezvenyresz_sorszam == 1){
                setKivalasztottHangszer1(undefined)
                setKivalasztottRendezvenyresz1(undefined)
            }else if(rendezvenyresz_sorszam == 2){
                setKivalasztottHangszer2(undefined)
                setKivalasztottRendezvenyresz2(undefined)
            }else{
                setKivalasztottHangszer3(undefined)
                setKivalasztottRendezvenyresz3(undefined)
            }
        }else{
            for (var hangszer of hangszerek){
                if (hangszer.Nev == e.target.value){
                    if (rendezvenyresz_sorszam == 1){
                        setKivalasztottHangszer1(hangszer.Nev)
                        setKivalasztottRendezvenyresz1(undefined)
                    }else if (rendezvenyresz_sorszam == 2){
                        setKivalasztottHangszer2(hangszer.Nev)
                        setKivalasztottRendezvenyresz2(undefined)
                    }else{
                        setKivalasztottHangszer3(hangszer.Nev)
                        setKivalasztottRendezvenyresz3(undefined)
                    }
                }
            }
        }
    }

    function onClick_hide(){
        setNev("")
        setKivalasztottHangszer1(undefined)
        setKivalasztottHangszer2(undefined)
        setKivalasztottHangszer3(undefined)
        setKivalasztottRendezvenyresz1(undefined);
        setKivalasztottRendezvenyresz2(undefined);
        setKivalasztottRendezvenyresz3(undefined);
        onHide()
    }
/*
    function onShow(){
        setKivalasztottRendezvenyresz1(vendeg.rendezvenyReszek[0]);
        setKivalasztottRendezvenyresz2(vendeg.rendezvenyReszek[1]);
    }
*/
    function onSelect_rendezvenyresz(rendezvenyresz_sorszam, e){
        if (e.target.value == -1){
            if (rendezvenyresz_sorszam == 1){
                setKivalasztottRendezvenyresz1(undefined)
            }else if (rendezvenyresz_sorszam == 2){
                setKivalasztottRendezvenyresz2(undefined)
            }else{
                setKivalasztottRendezvenyresz3(undefined)
            }
        }else{
            for (var i in rendezveny.rendezvenyReszek){
                var resz = rendezveny.rendezvenyReszek[i];
                if (resz.Id == e.target.value){
                    if (rendezvenyresz_sorszam == 1){
                        setKivalasztottRendezvenyresz1(resz)
                    }else if (rendezvenyresz_sorszam == 2){
                        setKivalasztottRendezvenyresz2(resz)
                    }else{
                        setKivalasztottRendezvenyresz3(resz)
                    }
                }
            }
        }
    }

    function onSubmitPreventer(event){event.preventDefault();};

    function ugyanaz_az_idosav(){
        if (jegyTipus.Rendezvenyreszlatogato == false) {
            return false;
        }
        let idosavok = [kivalasztottRendezvenyresz1, kivalasztottRendezvenyresz2, kivalasztottRendezvenyresz3].filter(Boolean).map(resz => resz.Idokezdet);
        let egyediIdosavok = [...new Set(idosavok)];
        return egyediIdosavok.length < idosavok.length;
    }

    function ugyanaz_a_hangszer() {
        if (jegyTipus.Rendezvenyreszlatogato == false) {
            return false;
        }
        let hangszerek = [kivalasztottHangszer1, kivalasztottHangszer2, kivalasztottHangszer3].filter(Boolean);
        let egyediHangszerek = [...new Set(hangszerek)];
        return egyediHangszerek.length < hangszerek.length;
    }

    function van_hangszer_de_nincs_idopont(){
        if (jegyTipus.Rendezvenyreszlatogato == false) {
            return false;
        }
        if (kivalasztottHangszer1 != undefined && kivalasztottRendezvenyresz1 == undefined) {
            return true;
        }
        if (kivalasztottHangszer2 != undefined && kivalasztottRendezvenyresz2 == undefined) {
            return true;
        }
        if (kivalasztottHangszer3 != undefined && kivalasztottRendezvenyresz3 == undefined) {
            return true;
        }
        return false;
    }

    return(
        <Modal className="modalVendeg" onHide={onHide} {...otherProps} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
            {/*onEntered={onShow.bind(this)}*/}
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={jegyTipus.Faiconcode} size='1x' color="#609090" />
            &nbsp;&nbsp;
            {jegyTipus.Gombfelirat}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <Form noValidate onSubmit={onSubmitPreventer.bind(this)} >
                {(rendezveny != undefined) ? 
                    <div>
                        <Form.Group className="mb-3" controlId="form_nev">
                            <Form.Label>{jegyTipus.Nev}</Form.Label>
                            <Form.Control type="text" placeholder="Név" value={nev || ''} required onChange={e => setNev(e.target.value)}/>
                            <Form.Control.Feedback type="invalid">A nevet kötelező megadni.</Form.Control.Feedback>
                            <Form.Text className="text-muted"></Form.Text>
                            <div className="jegyTipusMegjegyzes" dangerouslySetInnerHTML={{__html: jegyTipus.Gombmegjegyzeshtml}} />
                        </Form.Group>
                        {jegyTipus.Rendezvenyreszlatogato == 1 ? 
                            <div>
                                <p>
                                    Az alábbi listákból kiválaszthatók a hangszerek, amelyet ki szeretne
                                    próbálni a gyermek.<br />
                                    (A listákból hiányozhatnak hangszerek, hogyha azokra már beteltek a helyek!)
                                </p>
                                <Row>
                                    <Col>
                                        <Form.Select value={kivalasztottHangszer1 || -1}
                                            onChange={onSelect_hangszer.bind(this, 1)} aria-label="">
                                            <option value="-1">Nem kívánok hangszert kipróbálni</option>
                                            {hangszerek.map(function(hangszer){
                                                return (
                                                    <option key={"hang1_" + hangszer.Nev} value={hangszer.Nev}>{hangszer.Nev}</option>
                                                )
                                            })}                    
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        {kivalasztottHangszer1 != undefined ?
                                        <Form.Select value={(kivalasztottRendezvenyresz1 == undefined) ? -1 : kivalasztottRendezvenyresz1.Id}
                                            onChange={onSelect_rendezvenyresz.bind(this, 1)} aria-label="">
                                                <option key={"resz1_m1"} value={-1}>Válasszon időpontot!</option>
                                            {rendezveny.rendezvenyReszek.map(function(resz){
                                                let hagydki = false;
                                                if (resz.Nev != kivalasztottHangszer1){hagydki = true;}  // csak a 2. kiválasztott hangszerhez tartozó időpontok
                                                if (!hagydki){
                                                    return (
                                                        <option key={"resz1_" + resz.Id} value={resz.Id}>{resz.Idokezdet.substring(0, 5)} - {resz.Helyszin} (Szabad helyek: {resz.szabad_helyek})</option>
                                                    )
                                                }
                                            })}                    
                                        </Form.Select>
                                        : undefined
                                        }
                                    </Col>
                                </Row>
                                <br />
                                {/*
                                <p>
                                    A második két listából kihagytuk azokat a hangszereket, amelyeknél
                                    a legnagyobb sorbanállás szokott kialakulni.
                                </p>
                                */}
                                <br />

                                <Row>
                                    <Col>
                                        <Form.Select value={kivalasztottHangszer2 || -1}
                                            onChange={onSelect_hangszer.bind(this, 2)} aria-label="">
                                            <option value="-1">Nem kívánok hangszert kipróbálni</option>
                                            {hangszerek.map(function(hangszer){
                                                if (hangszer.Masodikkentisvalaszthato == 1){
                                                    return (
                                                        <option key={"hang2_" + hangszer.Nev} value={hangszer.Nev}>{hangszer.Nev}</option>
                                                    )
                                                }
                                            })}                    
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        {kivalasztottHangszer2 != undefined ?
                                        <Form.Select value={(kivalasztottRendezvenyresz2 == undefined) ? -1 : kivalasztottRendezvenyresz2.Id}
                                            onChange={onSelect_rendezvenyresz.bind(this, 2)} aria-label="">
                                                <option key={"resz2_m1"} value={-1}>Válasszon időpontot!</option>
                                            {rendezveny.rendezvenyReszek.map(function(resz){
                                                let hagydki = false;
                                                if (resz.Nev != kivalasztottHangszer2){hagydki = true;}  // csak a 2. kiválasztott hangszerhez tartozó időpontok
                                                if (!hagydki){
                                                    return (
                                                        <option key={"resz2_" + resz.Id} value={resz.Id}>{resz.Idokezdet.substring(0, 5)} - {resz.Helyszin} (Szabad helyek: {resz.szabad_helyek})</option>
                                                    )
                                                }
                                            })}                    
                                        </Form.Select>
                                        : undefined
                                        }
                                    </Col>
                                </Row>


                                <Row>
                                    <Col>
                                        <Form.Select value={kivalasztottHangszer3 || -1}
                                            onChange={onSelect_hangszer.bind(this, 3)} aria-label="">
                                            <option value="-1">Nem kívánok hangszert kipróbálni</option>
                                            {hangszerek.map(function(hangszer){
                                                if (hangszer.Masodikkentisvalaszthato == 1){
                                                    return (
                                                        <option key={"hang3_" + hangszer.Nev} value={hangszer.Nev}>{hangszer.Nev}</option>
                                                    )
                                                }
                                            })}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        {kivalasztottHangszer3 != undefined ?
                                        <Form.Select value={(kivalasztottRendezvenyresz3 == undefined) ? -1 : kivalasztottRendezvenyresz3.Id}
                                            onChange={onSelect_rendezvenyresz.bind(this, 3)} aria-label="">
                                                <option key={"resz3_m1"} value={-1}>Válasszon időpontot!</option>
                                            {rendezveny.rendezvenyReszek.map(function(resz){
                                                let hagydki = false;
                                                if (resz.Nev != kivalasztottHangszer3){hagydki = true;}  // csak a 2. kiválasztott hangszerhez tartozó időpontok
                                                if (!hagydki){
                                                    return (
                                                        <option key={"resz3_" + resz.Id} value={resz.Id}>{resz.Idokezdet.substring(0, 5)} - {resz.Helyszin} (Szabad helyek: {resz.szabad_helyek})</option>
                                                    )
                                                }
                                            })}                    
                                        </Form.Select>
                                        : undefined
                                        }
                                    </Col>
                                </Row>

                            </div>
                            : undefined
                        }
                    </div>
                :
                        undefined
                }
            </Form>                    


        </Modal.Body>
        <Modal.Footer>
            {!van_hangszer_de_nincs_idopont() && !ugyanaz_az_idosav() && !ugyanaz_a_hangszer() && (nev != '') ? 
                <Button onClick={onSave_inside.bind(this)}>Mentés</Button>
            : undefined}    
            {ugyanaz_az_idosav() ?
            <div className="ugyanaz_az_idosav">Ugyanarra az idősávra jelentkezett kétszer!</div>
            :
            <div className=""></div>}
            {ugyanaz_a_hangszer() ?
            <div className="ugyanaz_a_hangszer">Ugyanazt a hangszert választotta ki többször!</div>
            :
            <div className=""></div>}
            {van_hangszer_de_nincs_idopont() ?
            <div className="van_hangszer_de_nincs_idopont">Válasszon a hangszerhez időpontot is!</div>
            :
            <div className=""></div>}
            {(nev == '') ?
            <div className="nincs_nev">Adja meg a nevet!</div>
            : undefined}
            &nbsp;
            <Button variant="default" onClick={onClick_hide}>Mégse</Button>
        </Modal.Footer>
        </Modal>            
    )
}