import React,  { useEffect, useState, useRef } from 'react';
import { Link, useSearchParams, useLocation, useNavigate} from 'react-router-dom';
import './App.scss';
import md5 from 'crypto-js/md5';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col, Card, Nav, Navbar, Container, NavDropdown} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Regisztracio from './components/app/Regisztracio';
import Rendezvenyek from './components/app/Rendezvenyek';
import RendConf from './components/app/RendConf';
import Review from './components/app/Review';
import {TransitionGroup, CSSTransition} from "react-transition-group";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function App(props) {
  let query = useQuery();
  //const URL_EMAILBOL = "http://localhost:3000/?vid=9&auth=1c79cdcb98a5562f70e98462a9fce25d";
  //const URL_REVIEW = "http://localhost:3000/?mgrID=80&auth=76412b71a581aabfcb3af558eee2886b";
  const [forceRedraw, setForceRedraw] = useState(false);
  
  const [API_URL, setAPI_URL] = useState(undefined);
  const [apiErrorMessage, setApiErrorMessage] = useState(undefined);
  const [modalData, setModalData] = useState();
  const [modalShown, setModalShown] = useState();
  const [modalCallback, setModalCallback] = useState();
  const [commInProgress, setCommInProgress] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [vevo, setVevo] = useState(undefined);
  const [megrendelesDataFromMegrendelesLink, setMegrendelesDataFromMegrendelesLink] = useState(undefined);
  const [kivalasztottRendezvenyID, setKivalasztottRendezvenyID] = useState(undefined);
  window.setModalData = setModalData;
  window.setModalShown = setModalShown;
  window.setModalCallback = setModalCallback;
  let location = useLocation();
  let navigate = useNavigate();

  function getCommInProgress(){
    return commInProgress;
  }
  
  function getConfigData() {
    fetch('RendConfJs.json?' + new Date().getTime())
        .then(function(r) {
            return r.json()
        })
        .then(function(c) {
            setAPI_URL(c.url_api)
        });
  }

  function onLoaded_and_onAPIURLChange_and_onLocationChange(){
    if (API_URL == undefined){
      getConfigData()
    }else{
      if (RendConf.page_title == undefined){
        callAPI("General.getRendConf", {}, function(resp){
          RendConf.page_title = resp.data.page_title;
          RendConf.contact_email = resp.data.contact_email;
          RendConf.szabalyzatURL = resp.data.szabalyzatURL;
          onLocationChange()
          setForceRedraw(!forceRedraw);
        });
      }else{
        onLocationChange()
      }
    }
  }

  useEffect(onLoaded_and_onAPIURLChange_and_onLocationChange, [API_URL, location])

  function onLocationChange(){
    // "onLoad":
    // GET paraméter ellenőrzése (autoauth): vevo ID, rendezvénycsoport ID

    if (API_URL == undefined){
      return;
    }

    if ((location.pathname == '/regisztracio') && (kivalasztottRendezvenyID == undefined)) {
      navigate('/rendezvenyek', {replace: true})
    }

    if (searchParams.get("vid") !== null) {
      //
      //     LOGIN LINK !!! AUTOAUTH ELLENŐRZÉSE
      //
      var vid = searchParams.get("vid");   // vevő ID
      var auth = searchParams.get("auth");
      var neededHash = md5('vid' + vid);

      if (neededHash != auth){
          alert("Hibás link");
          return;
      }else{
        //
        //     AUTOAUTH ÉRVÉNYESNEK LÁTSZIK
        //
        
        callAPI("General.getAutoAuthVevo", {vevoID: vid}, function(resp){
          if (resp.data.vevo == undefined) {
            // A link jónak tnt, de a vevőID nem azonosít valódi vevőt
            alert("A link hibás vagy már nem érvényes!");
            return;  
          }
          // NEM JÖN RÉSZLETES INFÓ, CSAK A VEVŐ ADATAI ÉS AZ,
          // HOGY AZ EGYES RENDEZVÉNYEKRE VAN-E MÁR MEGRENDELÉSE!
          // resp.data.vevo
          // resp.data.vevo.megrendeltRendezvenyIDk = []
          var authData = resp.data;
          setVevo(resp.data.vevo)
          // vissza kell mennünk a paraméter nélküli kezdőoldalra
          navigate('/rendezvenyek', {replace: true})
        });
      }
    }else if (searchParams.get("mgrID") !== null) {
      //
      //     MEGRENDELÉS LINK !!! (FIZETÉS UTÁN)
      //

      var mgrID = searchParams.get("mgrID");   // megrendelés ID
      var auth = searchParams.get("auth");
      var neededHash = md5('mgrID' + mgrID);

      if (neededHash != auth){
          alert("Hibás link");
          return;
      }else{
        //
        //     megrendeles-link ÉRVÉNYESNEK LÁTSZIK
        //
        
        callAPI("General.getAutoAuthMegrendeles", {megrendelesID: mgrID}, function(resp){
          if (resp.data.megrendeles.Id == undefined){
            alert ("A regisztrácó nem található.")
          }else{
            setMegrendelesDataFromMegrendelesLink(resp.data.megrendeles);
            setKivalasztottRendezvenyID(resp.data.megrendeles.rendezveny.Id);
            setVevo(resp.data.megrendeles.vevo);
            if (resp.data.megrendeles.Fizetessikeres == 1){
              navigate('/review', {replace: true})
            }else{
              navigate('/regisztracio', {replace: true})
            }
          }
        });
      }
      
    }else{
      if (location.pathname == '/'){
        // Ha csak épp mos jött, akkor beküldjük a rendezvény-választóba (ami előbb ellenőrzi, hogy: 1. van-e már vevo és hogy 2. van-e már neve)
        navigate('/rendezvenyek', {replace: true})
      }
      
      //
      //      NINCS GET paraméter, nincs autoauth
      //
      // Lekérjük a rendezvényeket
      /*
      props.callAPI("General.getAktualisRendezvenyekInfo", undefined, function(resp){
          setRendezvenyek(resp.data.rendezvenyek)
          setLepesID(LEPESID_0a_EMAIL_FORM);
      });
      */
    }
    // CSAK AKKOR HÍVÓDJON ÚJRA, AMIKOR VÁLTOZIK A LOCATION!!!
  }

  async function callAPI(method, data, successHandler, errorHandler){
    setCommInProgress(true);
    if (API_URL == undefined){
      console.log("API_URL not set. Method: " + method)
    }else{
      const answer = await callAPIAsync(method, data);
      setCommInProgress(false);
      const answerText = await answer.text(); // Parse it as text
      try {
        const answerJson = JSON.parse(answerText); // Try to parse it as JSON
        if (answerJson.status === 'success'){
          successHandler(answerJson);
        }else{
          if (errorHandler !== undefined){
            errorHandler(answerJson);
          }
          answerJson.userMsg = answerJson.userMsg.replace(/\*/ig, "\n");
          alert(answerJson.userMsg);
        }
      } catch(err) {
        alert("Kommunikációs hiba lépett fel. Kérjük, kezdje újra a jelentkezést, vagy vegye fel a kapcsolatot iskolánkkal.");
        console.log(answerText);
        setApiErrorMessage(answerText)
      }  
    }
  }

  window.validateEmail = function(email) {
    //var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async function callAPIAsync(method, data) {
    if (data == undefined) {data = {}}
    var jsonstring = JSON.stringify({
        method: method,
        data: data,
        sessionID: window.mtrjel_sessionid
    });

    return fetch(API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: jsonstring
    })
  }

  function onSetNev(nev){
    callAPI("General.saveVevoNeve", {vevoID: vevo.Id, vevoNeve: nev}, function(resp){
      let ujvevo = {...vevo};
      ujvevo.Szemelynev = nev;  
      setVevo(ujvevo);
    });
  }
/*
  function getKivalasztottRendezvenyID(){
    return kivalasztottRendezvenyID;
  }
*/
  function onOk_rendezveny_kivalasztasa_utan(rendezvenyID){
    setKivalasztottRendezvenyID(rendezvenyID);
    //setTimeout(function(){
      navigate('/regisztracio', {replace: true})
    //}, 500)
  }

  function onClick_errorMessage(){
    setApiErrorMessage(undefined);
  }


  if (API_URL == undefined){
    return (<div />)
  }else{
    return (
      <div className="App">
          
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
          <Navbar.Brand >{RendConf.page_title}</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Regisztráció újrakezdése</Nav.Link>            
            </Nav>

            
            <Nav>
              
            
              <div className='app-emailcim'>
                Ha kérdése van: <FontAwesomeIcon icon="fa-envelope" size='1x' color="#ffffff" /> {RendConf.contact_email}
                </div>
                {/*
                <Nav.Link eventKey={2}  href={URL_EMAILBOL}>
              </Nav.Link>
              
              <Nav.Link eventKey={2} href={URL_REVIEW}>
                REVIEW
              </Nav.Link>
              */}
            </Nav>
            
          
          </Navbar.Collapse>
          {apiErrorMessage != undefined ? 
            <div className="apiErrorMessageBox">
              <div className="apiErrorMessage" dangerouslySetInnerHTML={{__html: apiErrorMessage}} />
              <Button onClick={onClick_errorMessage}>Close</Button>
            </div>
            : undefined
          }
        </Container>
        </Navbar>

        
          
            
              <Routes>
                <Route path="/review" element={
                  <Review 
                    callAPI={callAPI}
                    betoltottMegrendelesData={megrendelesDataFromMegrendelesLink}
                  />}
                />
                <Route path="/rendezvenyek" element={
                  <Rendezvenyek
                    callAPI={callAPI}
                    getCommInProgress={getCommInProgress}
                    authorizedVevo={vevo}
                    onSetNev={onSetNev}
                    onOkSelectedRendezvenyID={onOk_rendezveny_kivalasztasa_utan}
                  />}
                />
                <Route path="/regisztracio" element={
                  <Regisztracio
                    betoltottMegrendelesData={megrendelesDataFromMegrendelesLink}
                    callAPI={callAPI}
                    getCommInProgress={getCommInProgress}
                    rendezvenyID={kivalasztottRendezvenyID}
                    vevo={vevo}
                    setVevo={setVevo}
                    {...props}
                  />}
                />
              </Routes>
            
        
        
        </div>
    )
  }
}

export default App;